import { EuiFieldNumber, EuiFormRow } from "@elastic/eui";
import React from "react";

function MeetingPriceFIeld({
  value,
  setPrice,
}: {
  value: number;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <EuiFormRow label="Price">
      <EuiFieldNumber
        min={0}
        placeholder="Price"
        value={value}
        onChange={(e) => {
         setPrice(parseInt(e.target.value));
        }}
      />
    </EuiFormRow>
  );
}

export default MeetingPriceFIeld;
