import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { onAuthStateChanged } from "firebase/auth";
import { getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useToast from "../hooks/useToast";
import { firebaseAuth, meetingsRef } from "../utils/FirebaseConfig";
import { JaaSMeeting } from "@jitsi/react-sdk";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import { getUser } from "../app/slices/AuthSlice";
import { ReactMediaRecorder } from "react-media-recorder";
import { BsRecordCircle } from "react-icons/bs";
import { blob } from "stream/consumers";

export default function JoinMeeting() {
  const params = useParams();
  const navigate = useNavigate();
  const [createToast] = useToast();
  const [isAllowed, setIsAllowed] = useState(false);
  const [user, setUser] = useState<any>(undefined);
  const [meeting, setMeeting] = useState<any>(undefined);
  const [jwtToken, setJwtToken] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [timer, setTimer] = useState(0);
  const [isHover, setIsHover] = useState(false);

  const getMeetingData = useCallback(async () => {
    if (params.id) {
      const firestoreQuery = query(
        meetingsRef,
        where("meetingId", "==", params.id)
      );
      const response = await getDocs(firestoreQuery);
      if (response.docs.length) {
        const meetingData = response.docs[0].data();
        setMeeting(meetingData);
      }
    }
  }, [params.id]);

  // const checkAuthorization = useCallback(() => {
  //   if (meeting) {
  //     const isCreator = meeting.createdBy === user?.uid;
  //     if (meeting.meetingType === "1-on-1") {
  //       if (meeting.invitedUsers[0] === user?.uid || isCreator) {
  //         if (meeting.meetingDate === moment().format("L")) {
  //           setIsAllowed(true);
  //           fetchJwt();
  //         } else if (
  //           moment(meeting.meetingDate).isBefore(moment().format("L"))
  //         ) {
  //           createToast({ title: "Meeting has ended.", type: "danger" });
  //           navigate(user ? "/" : "/login");
  //         } else {
  //           createToast({
  //             title: `Meeting is on ${meeting.meetingDate}`,
  //             type: "warning",
  //           });
  //           navigate(user ? "/" : "/login");
  //         }
  //       } else navigate(user ? "/" : "/login");
  //     } else if (meeting.meetingType === "video-conference") {
  //       const index = meeting.invitedUsers.findIndex(
  //         (invitedUser: string) => invitedUser === user?.uid
  //       );
  //       if (index !== -1 || isCreator) {
  //         if (meeting.meetingDate === moment().format("L")) {
  //           setIsAllowed(true);
  //           fetchJwt();
  //         } else if (
  //           moment(meeting.meetingDate).isBefore(moment().format("L"))
  //         ) {
  //           createToast({ title: "Meeting has ended.", type: "danger" });
  //           navigate(user ? "/" : "/login");
  //         } else {
  //           createToast({
  //             title: `Meeting is on ${meeting.meetingDate}`,
  //             type: "warning",
  //           });
  //         }
  //       } else {
  //         createToast({
  //           title: `You are not invited to the meeting.`,
  //           type: "danger",
  //         });
  //         navigate(user ? "/" : "/login");
  //       }
  //     } else {
  //       setIsAllowed(true);
  //       fetchJwt();
  //     }
  //   }
  // }, [meeting, user, createToast, navigate]);

  const checkAuthorization = useCallback(() => {
    if (meeting) {
      const isCreator = meeting.createdBy === user?.uid;
      if (meeting.meetingType === "1-on-1") {
        if (meeting.invitedUsers[0] === user?.uid || isCreator) {
          if (meeting.meetingDate === moment().format("L")) {
            if (!jwtToken) {
              setIsAllowed(true);
              fetchJwt();
            }
          } else if (
            moment(meeting.meetingDate).isBefore(moment().format("L"))
          ) {
            createToast({ title: "Meeting has ended.", type: "danger" });
            navigate(user ? "/" : "/login");
          } else {
            createToast({
              title: `Meeting is on ${meeting.meetingDate}`,
              type: "warning",
            });
            navigate(user ? "/" : "/login");
          }
        } else navigate(user ? "/" : "/login");
      } else if (meeting.meetingType === "video-conference") {
        const index = meeting.invitedUsers.findIndex(
          (invitedUser: string) => invitedUser === user?.uid
        );
        if (index !== -1 || isCreator) {
          if (meeting.meetingDate === moment().format("L")) {
            if (!jwtToken) {
              setIsAllowed(true);
              fetchJwt();
            }
          } else if (
            moment(meeting.meetingDate).isBefore(moment().format("L"))
          ) {
            createToast({ title: "Meeting has ended.", type: "danger" });
            navigate(user ? "/" : "/login");
          } else {
            createToast({
              title: `Meeting is on ${meeting.meetingDate}`,
              type: "warning",
            });
          }
        } else {
          createToast({
            title: `You are not invited to the meeting.`,
            type: "danger",
          });
          navigate(user ? "/" : "/login");
        }
      } else {
        if (!jwtToken) {
          setIsAllowed(true);
          fetchJwt();
        }
      }
    }
  }, [meeting, user, createToast, navigate, jwtToken]);

  const fetchJwt = useCallback(async () => {
    const now = new Date();
    const expiration = Math.round(now.setHours(now.getHours() + 3) / 1000);
    const notBefore = Math.round(new Date().getTime() / 1000) - 10;
    try {
      const res = await fetch(
        "https://happyymeet.uc.r.appspot.com/token-generator/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            avatar: "",
            name: user?.displayName,
            email: user?.email,
            expTimestampSec: expiration,
            id: uuid(),
            moderator: true,
            nbfTimestampSec: notBefore,
            permissions: [
              "RECORDING",
              "LIVESTREAMING",
              "TRANSCRIPTION",
              "OUTBOUND_CALL",
              "MODERATION",
            ],
            regexRoom: false,
            roomName: "*",
          }),
        }
      );

      const data = await res.text();
      setJwtToken(data);
    } catch (error) {
      console.error("Failed to fetch JWT token:", error);
      createToast({ title: "Failed to fetch JWT token.", type: "danger" });
    }
  }, [user, createToast]);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      getMeetingData();
    }
  }, [user, getMeetingData]);

  useEffect(() => {
    if (meeting) {
      checkAuthorization();
    }
  }, [meeting, checkAuthorization]);
  useEffect(() => {
    let timerInterval: NodeJS.Timeout | undefined;

    if (isRecording) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
      setTimer(0);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isRecording]);
  const startRecording = () => {
    navigator.mediaDevices
      .getDisplayMedia({ video: true, audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream, {
          mimeType: "video/mp4",
        });
  
        setMediaRecorder(recorder);
        setIsRecording(true);
  
        const buffer: BlobPart[] = [];
        recorder.ondataavailable = (event) => {
          buffer.push(event.data);
        };
  
        recorder.onstop = () => {
          const blob = new Blob(buffer, { type: "video/mp4" });
  
          // Automatically download the recording
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "recording.mp4";
          a.click();
  
          // Prepare and upload the video to Cloudinary
          const formData = new FormData();
          formData.append("file", blob);
          formData.append("upload_preset", "jitsimeet");
          formData.append("cloud_name", "dpajml6fj");
  
          fetch("https://api.cloudinary.com/v1_1/dpajml6fj/video/upload", {
            method: "POST",
            body: formData,
          })
          .then((response) => response.json())
          .then((data) => {
            console.log("Upload successful:", data);
          })
          .catch((error) => {
            console.error("Upload failed:", error);
          });
        };
  
        recorder.start();
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });
  };
  

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  return isAllowed ? (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        className="myCallContainer"
        style={{ width: "100%", height: "100vh" }}
      >
        <JaaSMeeting
          appId="vpaas-magic-cookie-00392cb29dc64f8a825139b6be29559f"
          roomName={meeting.meetingName}
          jwt={jwtToken}
          configOverwrite={{
            disableThirdPartyRequests: true,
            disableLocalVideoFlip: true,
            disableModeratorIndicator: false,
            backgroundAlpha: 0.5,
            startWithAudioMuted: false,
            startWithVideoMuted: true,
            startScreenSharing: true,
            enableEmailInStats: false,
            fileRecordingsEnabled: true,
            recordAudioAndVideo: true,
          }}
          interfaceConfigOverwrite={{
            VIDEO_LAYOUT_FIT: "nocrop",
            MOBILE_APP_PROMO: false,
            TILE_VIEW_MAX_COLUMNS: 4,
          }}
          lang="fr"
          userInfo={{
            displayName: user?.displayName,
            email: user?.email,
          }}
          onReadyToClose={() => navigate(user ? "/mymeetings" : "/login")}
          getIFrameRef={(iframeRef) => {
            iframeRef.style.height = "800px";
          }}
        />
        <div
          style={{
            width: "60%",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            right: "0",
            textAlign: "right",
            zIndex: "10",
          }}
        >
          <p
            style={{
              color: "white",
              paddingRight: "15px",
              paddingBottom: "15px",
              visibility: isHover ? "visible" : "hidden",
            }}
          >
            Screen Recording
          </p>
          <button
            style={{
              background: "black",
              color: "white",
              padding: "15px",
              position: "relative",
              right: isHover ? "10px" : "-174px",
            }}
            onClick={isRecording ? stopRecording : startRecording}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={isRecording ? "blinking" : ""}
            >
              <span>
                <BsRecordCircle size={20} />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p>
                {isRecording
                  ? "Stop Recording Meeting"
                  : "Start Recording Meeting"}
                {isRecording && (
                  <div style={{ color: "red" }}>
                    <p>
                      Recording...{" "}
                      {new Date(timer * 1000).toISOString().substr(11, 8)}
                    </p>
                  </div>
                )}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
