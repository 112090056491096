import { useState } from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPanel,
  EuiProvider,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiFieldText,
  EuiFieldPassword,
  EuiFormRow,
} from '@elastic/eui';
import logo from '../assets/logo.png';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { firebaseAuth } from '../utils/FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { setUser } from '../app/slices/AuthSlice';
import React from 'react';

function EmailLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [resetEmail, setResetEmail] = useState<string>('');
  const [showResetForm, setShowResetForm] = useState<boolean>(false);

  const loginWithEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
      const { user } = result;

      // Vérifiez si l'email est vérifié
      if (!user.emailVerified) {
        setError('Please verify your email before signing in.');
        return;
      }

      dispatch(setUser({ uid: user.uid, email: user.email || '', name: user.displayName || 'Anonymous' }));
      navigate('/');
    } catch (err) {
      console.error('Email sign-in error:', err);
      setError('Failed to sign in with email.');
    }
  };

  const sendResetEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!resetEmail) {
      setError('Please enter your email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(firebaseAuth, resetEmail);
      setError(null);
      alert('Password reset email sent! Please check your inbox.');
      setShowResetForm(false); // Masquer le formulaire après l'envoi
    } catch (err) {
      console.error('Error sending password reset email:', err);
      setError('Failed to send password reset email.');
    }
  };

  return (
    <EuiProvider colorMode="dark">
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ width: '100vw', height: '100vh' }}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel paddingSize="xl">
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem>
                <EuiImage src={logo} alt="logo" size="230px" />
                <EuiSpacer size="xs" />
                <EuiText textAlign="center" grow={false}>
                  <h3>
                    <EuiTextColor>One Platform to</EuiTextColor>
                    <EuiTextColor color="#fca803"> connect</EuiTextColor>
                  </h3>
                </EuiText>

                <div>
                  <EuiText>
                    <h2>Login with Email</h2>
                  </EuiText>
                  <EuiSpacer size="m" />
                  <form onSubmit={loginWithEmail}>
                    <EuiFormRow label="Email">
                      <EuiFieldText
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Password">
                      <EuiFieldPassword
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                      />
                    </EuiFormRow>
                    {error && <EuiText color="danger">{error}</EuiText>}
                    <EuiSpacer size="m" />
                    <EuiButton type="submit" fill>
                      Login
                    </EuiButton>
                  </form>
                </div>

                <EuiSpacer size="m" />
                {!showResetForm ? (
                  <div>
                    <EuiText>
                      <h2>Forgot Password?</h2>
                    </EuiText>
                    <EuiButton onClick={() => setShowResetForm(true)}>
                      Reset Password
                    </EuiButton>
                  </div>
                ) : (
                  <div>
                    <EuiText>
                      <h2>Reset Password</h2>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <form onSubmit={sendResetEmail}>
                      <EuiFormRow label="Email">
                        <EuiFieldText
                          value={resetEmail}
                          onChange={(e) => setResetEmail(e.target.value)}
                          placeholder="Enter your email to reset password"
                        />
                      </EuiFormRow>
                      {error && <EuiText color="danger">{error}</EuiText>}
                      <EuiSpacer size="m" />
                      <EuiButton type="submit" fill>
                        Send Reset Email
                      </EuiButton>
                      <EuiSpacer size="m" />
                      <EuiButton onClick={() => setShowResetForm(false)}>
                        Cancel
                      </EuiButton>
                    </form>
                  </div>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiProvider>
  );
}

export default EmailLogin;
