import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./slices/AuthSlice";
import { meetingsSlice } from "./slices/MeetingSlice";
import { usermeetingSlice } from "./slices/UserMeetingSlice";
import { notificationsSlice } from "./slices/NotificationsSlice";

// Combine all slices into a single root reducer
const rootReducer = combineReducers({
  auth: authSlice.reducer,
  meetings: meetingsSlice.reducer,
  usermeeting: usermeetingSlice.reducer,
  notifications: notificationsSlice.reducer,
});

// Create the Redux store using the rootReducer
export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
