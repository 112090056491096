import { createSlice } from "@reduxjs/toolkit";
import { ToastType } from "../../utils/types";

interface usermeetingInitialState {
  toasts: Array<ToastType>;
}

const initialState: usermeetingInitialState = {
  toasts: [],
};

export const usermeetingSlice = createSlice({
  name: "usermeeting",
  initialState,
  reducers: {
    setUserMeetingToasts: (state, action) => {
      state.toasts = action.payload;
    },
  },
});

export const { setUserMeetingToasts } = usermeetingSlice.actions;
