import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setToasts } from "../app/slices/MeetingSlice";
import { setUserMeetingToasts } from "../app/slices/UserMeetingSlice";


function useToast() {
  const toasts = useAppSelector((happymeet) => happymeet.meetings.toasts);
  const userMeetingToasts = useAppSelector((happymeet) => happymeet.usermeeting.toasts);
  const dispatch = useAppDispatch();
  const createToast = ({
    title,
    type,
  }: {
    title: string;
    type: "success" | "primary" | "warning" | "danger" | undefined;
  }) => {
    dispatch(
      setToasts(
        toasts.concat({
          id: new Date().toISOString(),
          title,
          color: type,
        }),
      )
    );
  };
  const createUserMeetingToast = ({
    title,
    type,
  }: {
    title: string;
    type: "success" | "primary" | "warning" | "danger" | undefined;
  }) => {
    dispatch(
      setToasts(
        userMeetingToasts.concat({
          id: new Date().toISOString(),
          title,
          color: type,
        }),
      )
    );
  };
  return [createToast, createUserMeetingToast];
}

export default useToast;
