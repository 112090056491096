import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendSignInLinkToEmail, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { setUser } from '../app/slices/AuthSlice';
import {
  EuiButton,
  EuiFieldText,
  EuiFieldPassword,
  EuiFormRow,
  EuiProvider,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
} from '@elastic/eui';
import logo from '../assets/logo.png';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = getAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Créez l'utilisateur
      await createUserWithEmailAndPassword(auth, email, password);

      // Configurez les paramètres du code d'action
      const actionCodeSettings = {
        url: 'http://localhost:3000/finishSignUp', // Assurez-vous que l'URL est correcte
        handleCodeInApp: true
      };

      // Envoyez le lien de vérification
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      // Sauvegardez l'email dans le localStorage
      window.localStorage.setItem('emailForSignIn', email);

      console.log('Email de vérification envoyé!'); // Vérifiez ce message dans la console
      navigate('/confirmationpage');

    } catch (err) {
      console.error('Erreur lors de l\'inscription:', err);
      setError((err as Error).message);
    }
  };

  return (
    <EuiProvider colorMode="dark">
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ width: '100vw', height: '100vh' }}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel paddingSize="xl">
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem>
                <EuiImage src={logo} alt="logo" size="230px" />
                <EuiSpacer size="xs" />
                <EuiText textAlign="center" grow={false}>
                  <h3>
                    <EuiTextColor>One Platform to</EuiTextColor>
                    <EuiTextColor color="#fca803"> connect</EuiTextColor>
                  </h3>
                </EuiText>
                <div>
                  <form onSubmit={handleSubmit}>
                    <EuiFormRow label="Email">
                      <EuiFieldText
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Password">
                      <EuiFieldPassword
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="First Name">
                      <EuiFieldText
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter your first name"
                      />
                    </EuiFormRow>
                    {error && <EuiText color="danger">{error}</EuiText>}
                    <EuiButton type="submit" fill>
                      Sign Up
                    </EuiButton>
                  </form>
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiProvider>
  );
};

export default SignUpForm;
