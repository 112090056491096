import { addDoc, collection, getDocs, limit, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { MeetingType, UserMettingType } from "../utils/types";
import { firebaseDB, meetingsRef, usermeetingRef } from "../utils/FirebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import {
    EuiBadge,
    EuiBasicTable,
    EuiButtonIcon,
    EuiCopy,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
  } from "@elastic/eui";

type MegaType = {
    meeting : MeetingType,
    userInfo : any
    action : () => void
}
export function BadgePlayJoin({meeting, userInfo, action} : MegaType) {

    const [isThisUserLinkToAMeeting, setisThisUserLinkToAMeeting] = useState<boolean>(false);
    
    const fetchUserMeeting = () => {
        let userMeetingExist: boolean = false;
        const userMeetingQuery = query(usermeetingRef, where("uId", "==", userInfo?.uid), where("meetingId", "==", meeting?.meetingId), limit(1));
        getDocs(userMeetingQuery).then((response) => {
            if (response.docs.length > 0 ) {
                response.forEach((myUserMeeting) => {
                  const data = myUserMeeting.data() as UserMettingType;
                  if (data.uId === userInfo?.uid && data.meetingId === meeting.meetingId)
                    userMeetingExist = !userMeetingExist;
                    setisThisUserLinkToAMeeting(userMeetingExist)
                });
              }
        });
        
      };

    useEffect(() => fetchUserMeeting(), [])

    if(isThisUserLinkToAMeeting){
        //console.log(meeting.meetingId+" : C'est dejà payé")
        return (
          <EuiBadge color="success">
            <Link
              to={`/join/${meeting.meetingId}`}
              style={{ color: "black" }}
              target="_blank" 
              title="You already pay, just Join the meeting"
              rel="noopener noreferrer"
            >
              Join Now
            </Link>
          </EuiBadge>
        );
      }else{
        //console.log(meeting.meetingId+" : C'est pas payé")
        return(
          <EuiBadge color="success">
            <Link
              to={"#"}
              style={{ color: "black" }}
              rel="noopener noreferrer"
              onClick={action}
            >
              Pay & Join Now
            </Link>
          </EuiBadge>
        );
      }

}