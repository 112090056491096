import {
  EuiButtonIcon,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiText,
  EuiTextColor,
  EuiPopover, 
  EuiNotificationBadge,
} from "@elastic/eui";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { notificationsRef } from '../utils/FirebaseConfig';
import { useAppSelector } from "../app/hooks";
import { changeTheme } from "../app/slices/AuthSlice";
import { getUserNotifications, markAllNotificationsAsRead, clearUserNotifications } from "../app/slices/NotificationsSlice";
import {
  getCreateMeetingBreadCrumbs,
  getDashboardBreadCrumbs,
  getMeetingsBreadCrumbs,
  getMyMeetingsBreadCrumbs,
  getOneOnOneMeetingBreadCrumbs,
  getVideoConferenceBreadCrumbs,
  getWalletBreadCrumbs,
} from "../utils/breadcrumbs";
import { useSelector } from "react-redux";
import { firebaseAuth } from "../utils/FirebaseConfig";
import { query, where, getDocs } from "firebase/firestore";
import { BreadCrumbsType } from "../utils/types";
import { RootState } from "../app/store";
import { uid } from "chart.js/dist/helpers/helpers.core";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useAppSelector((happymeetApp) => happymeetApp.auth.userInfo?.name);
  const isDarkTheme = useAppSelector((happymeetApp) => happymeetApp.auth.isDarkTheme);
  const [breadCrumbs, setBreadCrumbs] = useState<Array<BreadCrumbsType>>([
    {
      text: "Dashboard",
    },
  ]);
  
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === "/") setBreadCrumbs(getDashboardBreadCrumbs(navigate));
    else if (pathname === "/create")
      setBreadCrumbs(getCreateMeetingBreadCrumbs(navigate));
    else if (pathname === "/create1on1")
      setBreadCrumbs(getOneOnOneMeetingBreadCrumbs(navigate));
    else if (pathname === "/videoconference")
      setBreadCrumbs(getVideoConferenceBreadCrumbs(navigate));
    else if (pathname === "/mymeetings")
      setBreadCrumbs(getMyMeetingsBreadCrumbs(navigate));
    else if (pathname === "/meetings") {
      setBreadCrumbs(getMeetingsBreadCrumbs(navigate));
    }
    else if (pathname === "/wallet") {
      setBreadCrumbs(getWalletBreadCrumbs(navigate));
    }
  }, [location, navigate]);

  const [isNotificationPopoverOpen, setIsNotificationPopoverOpen] = useState(false);  
  const currentUserId: string = useSelector(
    (state: RootState) => state.auth.userInfo?.uid || ""
  );
  

  const notifications = useSelector((state: RootState) =>
    state.notifications.filter((n) => n.userId === currentUserId)
  );
  const unreadNotificationsCount = notifications.filter(n => !n.read).length;

  const toggleNotificationPopover = () => {
    setIsNotificationPopoverOpen(!isNotificationPopoverOpen);

    if (!isNotificationPopoverOpen) {
      // Lorsque le popover est ouvert, marquez toutes les notifications comme lues
      dispatch(markAllNotificationsAsRead(currentUserId));
    }
  };

  const closeNotificationPopover = () => {
    setIsNotificationPopoverOpen(false);
  };
  const clearNotifications = () => {
    dispatch(clearUserNotifications(currentUserId));
  };

  const logout = () => {
    signOut(firebaseAuth);
  };

  const invertTheme = () => {
    const theme = localStorage.getItem("happymeet-theme");
    localStorage.setItem("happymeet-theme", theme === "light" ? "dark" : "light");
    dispatch(changeTheme({ isDarkTheme: !isDarkTheme }));
  };
  const section = [
    {
      items: [
        <Link to="/">
          <EuiText>
            <h2 style={{ padding: "0 1vw" }}>
              <EuiTextColor color="#fca803">Happymeet</EuiTextColor>
            </h2>
          </EuiText>
        </Link>,
      ],
    },
    {
      items: [
        <>
          {userName ? (
            <EuiText>
              <h3>
                <EuiTextColor color="white">Hello, </EuiTextColor>
                <EuiTextColor color="#fca803">{userName}</EuiTextColor>
              </h3>
            </EuiText>
          ) : null}
        </>,
      ],
    },
    {
      items: [
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ gap: "2vw" }}
        >
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiPopover
              button={
                <div style={{ position: "relative" }}>
                  <EuiButtonIcon
                    onClick={toggleNotificationPopover}
                    iconType="bell"
                    display="fill"
                    size="s"
                    color="primary"
                    aria-label="notification-button"
                  />
                {unreadNotificationsCount > 0 && (
              <EuiNotificationBadge
                style={{
                  position: "absolute",
                  top: -5,
                  right: -5,
                }}
              >
                {unreadNotificationsCount}
              </EuiNotificationBadge>
            )}
          </div>
        }
        isOpen={isNotificationPopoverOpen}
        closePopover={closeNotificationPopover}
      >
        <div style={{ padding: "10px" }}>
          <EuiText size="s">
            {notifications.length > 0 ? (
              notifications.map((n) => (
                <div key={n.id} style={{ marginBottom: "10px", borderBottom: "1px solid #ccc", paddingBottom: "5px" }}>
                  <p>{n.message}</p>
                  <small>{new Date(n.date).toLocaleString()}</small>
                </div>
              ))
            ) : (
              "You have no new notifications."
            )}
          </EuiText>
          {notifications.length > 0 && (
            <EuiButton
              size="s"
              color="danger"
              fullWidth
              onClick={clearNotifications}
              style={{ marginTop: "10px" }}
            >
              Clear Notifications
            </EuiButton>
          )}
        </div>
      </EuiPopover>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            {isDarkTheme ? (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="sun"
                display="fill"
                size="s"
                color="warning"
                aria-label="theme-button-light"
              />
            ) : (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="moon"
                display="fill"
                size="s"
                color="primary"
                aria-label="theme-button-dark"
              />
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={logout}
              iconType="lock"
              display="fill"
              size="s"
              aria-label="logout-button"
            />
          </EuiFlexItem>
        </EuiFlexGroup>,
      ],
    },
  ];

  const responsiveSection = [
    {
      items: [
        <Link to="/">
          <EuiText>
            <h2 style={{ padding: "0 1vw" }}>
              <EuiTextColor color="#fca803">Happymeet</EuiTextColor>
            </h2>
          </EuiText>
        </Link>,
      ],
    },
    {
      items: [
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ gap: "2vw" }}
        >
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            {isDarkTheme ? (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="sun"
                display="fill"
                size="s"
                color="warning"
                aria-label="theme-button-light"
              />
            ) : (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="moon"
                display="fill"
                size="s"
                color="primary"
                aria-label="theme-button-dark"
              />
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={logout}
              iconType="lock"
              display="fill"
              size="s"
              aria-label="logout-button"
            />
          </EuiFlexItem>
        </EuiFlexGroup>,
      ],
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 480) {
      // sectionSpliced.splice(1, 1);
      // setSection(sectionSpliced);
      setIsResponsive(true);
    }
  }, []);

  return (
    <>
      <EuiHeader
        style={{ minHeight: "8vh" }}
        theme="dark"
        sections={isResponsive ? responsiveSection : section}
      />
      <EuiHeader
        style={{ minHeight: "8vh" }}
        sections={[
          {
            breadcrumbs: breadCrumbs,
          },
        ]}
      />
    </>
  );
}
