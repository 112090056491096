import React from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPanel,
  EuiProvider,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <EuiProvider colorMode="dark">
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ width: '100vw', height: '100vh' }}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel paddingSize="xl">
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem>
                <EuiImage src={logo} alt="logo" size="230px" />
                <EuiSpacer size="xs" />
                <EuiText textAlign="center" grow={false}>
                  <h3>
                    <EuiText color="success">Verification Email Sent</EuiText>
                  </h3>
                </EuiText>
                <EuiSpacer size="l" />
                <EuiText textAlign="center" grow={false}>
                  <p>
                    Please check your email to verify your address. Once you have verified your email,
                    you can log in to your account.
                  </p>
                </EuiText>
                <EuiSpacer size="m" />
                <EuiButton fill onClick={() => navigate('/login')}>
                  Back to Login
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiProvider>
  );
};

export default ConfirmationPage;
