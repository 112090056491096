import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Notification {
  id: string;
  userId: string;
  message: string;
  date: string;
  read: boolean;
  url: string;
}

const initialState: Notification[] = [];

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.push(action.payload);
    },
    getUserNotifications: (state, action: PayloadAction<string>) => {
      return state.filter(notification => notification.userId === action.payload);
    },
    markAllNotificationsAsRead: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      state.forEach(notification => {
        if (notification.userId === userId) {
          notification.read = true;
        }
      });
    },
    clearUserNotifications: (state, action: PayloadAction<string>) => {
      return state.filter(notification => notification.userId !== action.payload);
    },
  },
});

export const { addNotification, getUserNotifications,markAllNotificationsAsRead, clearUserNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
