import {
  EuiBadge,
  EuiBasicTable,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
} from "@elastic/eui";

import { addDoc, collection, getDocs, limit, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import Header from "../components/header";
import useAuth from "../hooks/useAuth";

import { firebaseDB, meetingsRef, usermeetingRef } from "../utils/FirebaseConfig";
import { MeetingType, UserMettingType } from "../utils/types";
import { useKKiaPay } from "kkiapay-react";
import useToast from "../hooks/useToast";
import { cp } from "fs";
import { BadgePlayJoin } from "./BadgePlayJoin";

export default function Meeting() {
  useAuth();
  const userInfo = useAppSelector((happymeet) => happymeet.auth.userInfo);
  const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [usermeeting, setUserMeeting] = useState<Array<UserMettingType>>([]);
  const { openKkiapayWidget, addSuccessListener } = useKKiaPay();
  const navigate = useNavigate();
  const [createUserMeetingToast] = useToast();

  useEffect(() => {
    const getMyMeetings = async () => {
      const firestoreQuery = query(meetingsRef);
      const fetchedMeetings = await getDocs(firestoreQuery);
      if (fetchedMeetings.docs.length) {
        const myMeetings: Array<MeetingType> = [];
        fetchedMeetings.forEach((meeting) => {
          const data = meeting.data() as MeetingType;
          if (data.createdBy === userInfo?.uid)
            myMeetings.push(meeting.data() as MeetingType);
          else if (data.meetingType === "anyone-can-join")
            myMeetings.push(meeting.data() as MeetingType);
          else {
            const index = data.invitedUsers.findIndex(
              (user: string) => user === userInfo?.uid
            );
            if (index !== -1) {
              myMeetings.push(meeting.data() as MeetingType);
            }
          }
        });

        setMeetings(myMeetings);
      }
    };
    if (userInfo) getMyMeetings();
    const script = document.createElement('script');
	  script.src = "https://8x8.vc/external_api.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [userInfo]);

  const meetingColumns = [
    {
      field: "meetingName",
      name: "Meeting Name",
    },
    {
      field: "meetingType",
      name: "Meeting Type",
    },
    {
      field: "meetingDate",
      name: "Meeting Date",
    },
    {
      field: "price",
      name: "Meeting Price",
    },
    {
      field: "",
      name: "Status",
      render: (meeting: MeetingType) => {        
        const openkkiapay = () => {
          openKkiapayWidget({
            amount: meeting.price,
            key: 'aba23e000a4211eeabbab72ba84a8978',
            sandbox: true,
            name: userInfo?.name,
            email: userInfo?.email,
            //callback: `${window.location.origin}/join`+meeting.meetingId,
          });
          addSuccessListener(response  => {
            console.log("Entré dans success avec comme id  : ", response.transactionId + "user :" + userInfo?.uid + "meeting : "+ meeting.meetingId);
            if (response) {

              const firebaseUserMetting: Array<UserMettingType> = [];

              firebaseUserMetting.push({
                uId: userInfo?.uid,
                meetingId: meeting.meetingId,
                transactionId: response.transactionId,
              });

              addDoc(collection(firebaseDB, "usermeeting"), {
                uId: userInfo?.uid,
                meetingId: meeting.meetingId,
                transactionId: response.transactionId,
              });

              setUserMeeting(firebaseUserMetting);
              createUserMeetingToast({ title: "Meeting payment success.", type: "success" });
              window.open("/join/"+meeting.meetingId, "_blank");
            }
          });
        };

        // const fetchUserMeeting = async () => {
        //   let userMeetingExist: boolean = false;
        //   const userMeetingQuery = query(usermeetingRef, where("uId", "==", userInfo?.uid), where("meetingId", "==", meeting?.meetingId), limit(1));
        //   const fetchedUserMeeting = await getDocs(userMeetingQuery);
        //   if (fetchedUserMeeting.docs.length > 0 ) {
        //     fetchedUserMeeting.forEach((myUserMeeting) => {
        //       const data = myUserMeeting.data() as UserMettingType;
        //       if (data.uId === userInfo?.uid && data.meetingId === meeting.meetingId)
        //         userMeetingExist = !userMeetingExist;
        //     });
        //   }
        //   return  userMeetingExist;
        // };

        if (meeting.status) {
          if (meeting.meetingDate === moment().format("L")) {
            // if(isLoading){
            //   return (<EuiBadge color="danger">Loading...</EuiBadge>)
            // }else{}

            if(meeting.price > 0) {
              
              if (meeting.createdBy === userInfo?.uid){
                return (
                <EuiBadge color="success">
                  <Link
                    to={`/join/${meeting.meetingId}`}
                    style={{ color: "black" }}
                    target="_blank" 
                    title="You are owner, just join"
                    rel="noopener noreferrer"
                  >
                    Join Now
                  </Link>
                </EuiBadge>
                );
              }else{
                return <BadgePlayJoin meeting={meeting} userInfo={userInfo} action={openkkiapay} key={meeting.meetingId}></BadgePlayJoin>
                // if(isThisUserLinkToAMeeting){
                //   //console.log(meeting.meetingId+" : C'est dejà payé")
                //   return (
                //     <EuiBadge color="success">
                //       <Link
                //         to={`/join/${meeting.meetingId}`}
                //         style={{ color: "black" }}
                //         target="_blank" 
                //         title="You already pay, just Join the meeting"
                //         rel="noopener noreferrer"
                //       >
                //         Join Now
                //       </Link>
                //     </EuiBadge>
                //   );
                // }else{
                //   //console.log(meeting.meetingId+" : C'est pas payé")
                //   return(
                //     <EuiBadge color="success">
                //       <Link
                //         to={"#"}
                //         style={{ color: "black" }}
                //         rel="noopener noreferrer"
                //         onClick={openkkiapay}
                //       >
                //         Pay & Join Now
                //       </Link>
                //     </EuiBadge>
                //   );
                // }
              } 
            }else{
              return (
                <EuiBadge color="success">
                  <Link
                    to={`/join/${meeting.meetingId}`}
                    style={{ color: "black" }}
                    target="_blank" 
                    title="It's free meeting, just join"
                    rel="noopener noreferrer"
                  >
                    Join Now
                  </Link>
                </EuiBadge>
              );
            }

          } else if (
            moment(meeting.meetingDate).isBefore(moment().format("L"))
          ) {
            return <EuiBadge color="default">Ended</EuiBadge>;
          } else if (moment(meeting.meetingDate).isAfter()) {
            return <EuiBadge color="primary">Upcoming</EuiBadge>;
          }
        } else return <EuiBadge color="danger">Cancelled</EuiBadge>;
      },
    },
    {
      field: "meetingId",
      name: "Copy Link",
      width: "10%",
      render: (meetingId: string, meeting: MeetingType) => {
        let isThisUserLinkToAMeeting : boolean = false
        if (meeting.meetingDate === moment().format("L")) {
          if(meeting.price > 0){
            // if(isLoading){
            //   return (<EuiBadge color="danger">Loading...</EuiBadge>)
            // }else{
              if (meeting.createdBy === userInfo?.uid){
                return (
                  <EuiCopy
                    textToCopy={`${process.env.REACT_APP_HOST}/join/${meetingId}`}
                  >
                    {(copy: any) => (
                      <EuiButtonIcon
                        iconType="copy"
                        onClick={copy}
                        display="base"
                        aria-label="meeting-copy"
                      />
                    )}
                  </EuiCopy>
                );
              }else{
                if(isThisUserLinkToAMeeting){
                  return (
                    <EuiCopy
                      textToCopy={`${process.env.REACT_APP_HOST}/join/${meetingId}`}
                    >
                      {(copy: any) => (
                        <EuiButtonIcon
                          iconType="copy"
                          onClick={copy}
                          display="base"
                          aria-label="meeting-copy"
                        />
                      )}
                    </EuiCopy>
                  );
                }else{
                  return (
                    <EuiCopy
                      textToCopy={`You Need To Pay First`}
                    >
                      {(copy: any) => (
                        <EuiButtonIcon
                          iconType="copy"
                          onClick={copy}
                          display="base"
                          aria-label="meeting-copy"
                        />
                      )}
                    </EuiCopy>
                  );
                }
              // }
            }
          }
        }else if (
          moment(meeting.meetingDate).isBefore(moment().format("L"))
        ) {
          return <EuiBadge color="default">Ended</EuiBadge>;
        } else if (moment(meeting.meetingDate).isAfter()) {
          return <EuiBadge color="primary">Upcoming</EuiBadge>;
        }
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Header />
       <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
        <EuiFlexItem>
          <EuiPanel>
            <EuiBasicTable items={meetings} columns={meetingColumns} />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
